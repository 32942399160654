// import { CacheFirst, NetworkOnly } from 'workbox-strategies';
import { precacheAndRoute } from 'workbox-precaching';
// import { ExpirationPlugin } from 'workbox-expiration';
// import {initialize as initializeOfflineAnalytics} from 'workbox-google-analytics';
// import { registerRoute, setCatchHandler } from 'workbox-routing';
import { skipWaiting, clientsClaim } from 'workbox-core';
import 'workbox-sw';
import { WorkboxSW } from 'workbox-sw';

declare var self: ServiceWorkerGlobalScope;
declare var workbox: WorkboxSW;
declare var __WB_MANIFEST: any;

workbox.setConfig({
  debug: true
});

precacheAndRoute(__WB_MANIFEST, {
  ignoreURLParametersMatching: [/file/]
});

console.log('start of workbox!');

// This is the code piece that GenerateSW mode can't provide for us.
// This code listens for the user's confirmation to update the app.
addEventListener('message', async (event) => {
  if (!event.data) {
    return;
  }
  console.log('sw recieve message:', event);
  const eventType = event?.data?.type;
  console.log('event type', eventType);
  if (eventType) {
    switch (eventType) {
      case 'FORCE_PWA_UPDATE':
      case 'UNREGISTER':
        self.registration.unregister();

        const windows = await self.clients.matchAll({ type: 'window' });
        for (const window of windows) {
          window.postMessage({ type: 'RELOAD' });
        }
        break;
    }
  }
});

// Updating SW lifecycle to update the app after user triggered refresh
skipWaiting();
clientsClaim();
